<template>
  <section>
    <!--Popup To Show Payment Methods and coupon-->
    <Popup
      class="payment-popup"
      :width="paymentWidth"
      :margin="paymentMargin"
      :radiusType="radiusType"
      :closePopupEvent="closePopup"
      v-if="showPayment"
    >
      <div class="vlive-payment" slot="component">
        <!--Showing Order summary and coupon container-->
        <OrderSummary
          :couponCodeProp="localCouponCode"
          :orderDetails="paymentDetail"
          @totalAfterDiscount="totalAfterDiscount"
        ></OrderSummary>

        <!--Payment Methods List-->
        <NoorPaymentOptions
          :paypalDetail="paypalDetail"
          :checkoutDetail="checkoutDetail"
          :paypalSubDetail="paypalSubDetail"
          :purchaseDetail="paymentDetail"
          :finalPromoAmounts="finalPromoAmounts"
          :precheckData="paymentPrecheckData"
        ></NoorPaymentOptions>
      </div>
    </Popup>

    <!--Popup To Show Payment Response-->
    <Popup
      class="post-payment-popup"
      :width="width"
      :margin="margin"
      :radiusType="radiusType"
      :closePopupEvent="closePopup"
      v-else
    >
      <!--Showing Loader Or Payment Error-->
      <div class="precheck" slot="component" v-if="!isPaymentSuccess">
        <Loading v-if="loader"></Loading>
        <p class="paymentError" v-else>{{ paymentError }}</p>
      </div>

      <!--Payment Response Handling-->
      <div class="paymentSuccess" slot="component" v-else>
        <!--Shoing Payment In Progress UI-->
        <div v-if="!loader && !transactionSuccess">
          <p class="please-wait">{{ $t("please wait") }}</p>
          <p class="payment-progress">{{ $t("your payment is in progress") }}</p>
          <img class="loading-gif" src="@/assets/gif/30.gif" />
        </div>

        <!--Showing Loader-->
        <div v-else-if="loader && !transactionSuccess">
          <Loading></Loading>
        </div>

        <!--Payment Success Response Handling-->
        <div v-else-if="!loader && transactionSuccess">
          <p class="payment-successfull">{{ paymentSuccessResponse }}</p>
          <div
            :class="[localDisplayLang === 'ara' ? 'subscription-success-card-right' : 'subscription-success-card']"
            v-if="transactionType === 'SUBSCRIPTION'"
          >
            <img class="tick" src="@/assets/icons/Check1.svg" />
            <p class="plan-title">{{ paymentDetail.detail.planname }}</p>
            <!-- <p class="plan-type">{{ paymentDetail.detail.planinterval + 'LY' + ' ' +  paymentDetail.detail.plantype }}</p> -->
            <!-- <p
              class="plan-interval"
            >{{ paymentDetail.detail.planinterval === 'MONTH' ? 30 : 365 }} days {{ paymentDetail.detail.plantag }} plan</p>-->
            <p class="plan-interval" dir="ltr" v-if="localDisplayLang === 'ara'">
              {{ retrievePlanInterval(paymentDetail, localDisplayLang) }}
            </p>
            <p class="plan-interval" dir="ltr" v-else>
              {{ retrievePlanInterval(paymentDetail, localDisplayLang) }}
            </p>
          </div>
          <div class="purchase-success-card" v-else>
            <div class="poster-card" :style="[{ 'background-image': `url(${getPoster()})` }]">
              <img class="purchase-tick" src="@/assets/icons/Check1.svg" />
            </div>
            <p class="purchase-content-title">{{ paymentDetail.content.title }}</p>
          </div>
        </div>
      </div>
    </Popup>
  </section>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapMutations, mapActions } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import Utility from "@/mixins/Utility.js";
import { store } from "@/store/store";
import { mapGetters } from "vuex";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    paymentDetail: {
      type: Object,
    },
    couponCode: {
      type: String,
    },
  },
  data() {
    return {
      width: "35%",
      margin: "20% auto",
      paymentWidth: "60%",
      paymentMargin: "10% auto",
      radiusType: "full",
      responseError: null,
      showPayment: false,
      isPaymentSuccess: false,
      paymentError: null,
      loader: true,
      transactionSuccess: false,
      paymentReferenceid: null,
      counter: 10,
      gateways: null,
      transactionType: null,
      paymentSuccessResponse: null,
      planList: [],
      localDisplayLang: null,
      paypalDetail: null,
      checkoutDetail: null,
      paypalSubDetail: null,
      listedGateways: [],
      finalPromoAmounts: null,
      localCouponCode : null,
      paymentPrecheckData: null,
			planDiscountCheckout: null,
			successAmount: null
    };
  },
 computed: {
    ...mapGetters(["appConfig"]),
	},
  watch: {
    showPayment(val) {
      if (val) {
        if (document.body.style.overflow === "auto") {
          document.body.style.overflow = "hidden";
        }
      }
    },
  },
  created() {
    console.log("payment created --- NEW > ", this.paymentDetail);
    if( this.couponCode) {
      this.localCouponCode = this.couponCode;
    } else if(this.paymentDetail && this.paymentDetail.coupon) {
      this.localCouponCode = this.paymentDetail.coupon;
    } 

    let paymentDetail = this.paymentDetail;

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    if (paymentDetail.hasOwnProperty("referenceid")) {
      this.showPayment = false;
      this.loader = true;
      this.transactionType = "SUBSCRIPTION";

      let payload = {
        planvisibility: "YES",
        // descriptiontest: "STC",
        // ooredooexclude: "NO",
        // du_uae_exclude: "NO",
        displaylanguage: this.localDisplayLang,
      };

      eventBus.$emit("planList_subscriptions", payload);
    }

    if (!paymentDetail.hasOwnProperty("referenceid")) {
      if (paymentDetail.transactionPurpose) {
        this.transactionType = paymentDetail.transactionPurpose;
      }
      if (this.transactionType === "SUBSCRIPTION") {
        this.subscriptionPrecheck(paymentDetail);
      }
    }
this.planDiscountCheckout = this.appConfig.planDiscountCheckout;

  },
  mounted() {
    // eventBus.$on(`precheck-response-${this.paymentDetail.detail.planid}`, response => {
    //   console.log("precheck response", response);
    //   if (response.reason) {
    //     this.showPayment = false;
    //     this.paymentError = response.reason;
    //   } else {
    //     this.listedGateways = this.paymentDetail.gateways;
    //     this.showPayment = true;
    //     this.paypalDetail = this.setUpGatewayDetail("PAYPALSUB");
    //     this.checkoutDetail = this.setUpGatewayDetail("CHECKOUT");
    //     this.paypalSubDetail = this.setUpGatewayDetail("PAYPALSUB");
    //   }
    // });

    // if (this.couponCode)
    eventBus.$on("planlist-subscriptions-response", (response) => {
      console.log("planlist response --- index", response);
      if (!response.reason) {
        this.planList = response.data;

        let index = this.planList.findIndex((element, i) => {
          console.log("plan list index", i, element);
          console.log("plan list index ---- 2", i, this.paymentDetail);
          return element.planid === this.paymentDetail.detail;
        });

        console.log("THE FINAL INDEX", index);

        if (index > -1) {
          this.paymentDetail.detail = this.planList[index];
          console.log("payment detail from payment", this.paymentDetail);
          this.getPaymentDetail();
        }
      }
    });

    // if (!this.paymentDetail.hasOwnProperty("referenceid")) {
    //   eventBus.$on("listgateway-response", response => {
    //     console.log("gateway response", response);
    //     this.loader = false;
    //     if (response.reason) {
    //       this.showPayment = false;
    //       this.paymentError = response.reason;
    //     } else {
    //       this.gateways = response;
    //       this.listedGateways = response.data;
    //       this.showPayment = true;

    //       this.paypalDetail = this.setUpGatewayDetail("PAYPAL");
    //       this.checkoutDetail = this.setUpGatewayDetail("CHECKOUT");
    //     }
    //   });
    // }

    eventBus.$on("payment-success", (response) => {
      this.paymentReferenceid = response;
      this.showPayment = false;
      this.isPaymentSuccess = true;
      this.decrementCounter();
    });

    eventBus.$on("paymentDetail-response", (response) => {
			this.successAmount = response.amount ? response.amount : null;
      console.log("payment detail response", response);
      this.showPayment = false;

      let precheckData = localStorage.getItem("payment_analytics_data");

      if (precheckData) {
        precheckData = JSON.parse(precheckData);
      }

      if (response.transactionstatus === "SUCCESS") {
        if (this.transactionType === "SUBSCRIPTION") {
          this.setNewSubscription(true);
          console.log("new subscription event fired", response);
					//this.successAmount = response.amount;
          this.paymentSuccessResponse = this.$t("You have successfully subscribed");

          const browser = this.getPlatformType();

          //charged event.
          let data = {
            payment_mode: this.getPaymentMode(response.gwprovider),
            subscription_start_date: response.paidon,
            subscription_end_date: precheckData.nextbilling ? precheckData.nextbilling : "",
            transaction_id: response.transactionid,
            amount: response.amount / 100,
            currency_code: response.currency,
            payment_id: response.paymentid,
            promo_code: precheckData.couponCode ? precheckData.couponCode : "",
            discount_amount: precheckData.discountPrice ? precheckData.discountPrice : response.amount / 100,
            free_trial: this.getFreeTrial(),
            plan_id: this.paymentDetail.detail.planid,
            plan_name: this.paymentDetail.detail.planname,
            platform: this.getPlatform(),
            platform_type: browser.browser,
            object_id: "",
            object_name: "",
            object_quality: "",
          };

          //cevertap event
          // this.chargedCleverEvent(data);

          //branch purchase subscribe event
          this.purchaseSubscribeBranchEvent(data);

          // Google analytics events
          this.subscriptionDone(data);

          // Facebook Pixel Events
          this.faceBookSubscribeEvent(data);
        } else if (this.transactionType === "PURCHASE") {
          //write transaction success mutation here.
					this.successAmount = response.amount ? response.amount : null;
          console.log("new purchase event fired");
          this.setNewPurchase(true);
          this.paymentSuccessResponse = "You Have Successfully Purchased";

          // let data = {
          //   Transaction_ID: response.transactionid,
          //   Amount: response.amount / 100,
          //   Currency_Code: response.currency,
          //   Payment_ID: response.paymentid,
          //   Free_Trial: "",
          //   Payment_Mode: response.transactionmode,
          //   Promo_Code: response.couponid,
          //   Discount_Amount: response.discount,
          //   Object_ID: response.objectid,
          //   Object_Name: this.paymentDetail.title,
          //   Object_Quality: this.paymentDetail.contentdetails[0].quality
          // };

          // //cevertap event
          // this.chargedCleverEvent(data);

          // //branch purchase subscribe event
          // this.purchaseSubscribeBranchEvent(payload);
        }
        //eventBus.$emit("triggerInitView", payload);
        this.isPaymentSuccess = true;
        this.transactionSuccess = true;
        this.loader = false;
      } else if (response.transactionstatus === "PENDING") {
        console.log("payment status is pending");
        this.isPaymentSuccess = false;
        this.loader = false;
        this.paymentError = "Your payment is in pending";
      } else if (response.transactionstatus === "FAILED") {
        console.log("the Payment failed log", response);
				this.successAmount = response.amount ? response.amount : null;
        this.isPaymentSuccess = false;
        this.loader = false;
        this.paymentError = `Your payment has failed ${response.reason ? " : " + response.reason : ""}`;

        const browser = this.getPlatformType();

        let data = {
          error_reason: response.reason ? response.reason : "",
          error_type: this.getPaymentErrorType(response.reason),
          payment_mode: this.getPaymentMode(response.gwprovider),
          amount: response.amount / 100,
          currency_code: response.currency,
          payment_id: response.paymentid,
          transaction_id: response.transactionid ? response.transactionid : "",
          Promo_Code: "",
          discount_amount: precheckData.discountPrice ? precheckData.discountPrice : response.amount / 100,
          free_trial: this.getFreeTrial(),
          plan_id: this.paymentDetail.detail.planid,
          plan_name: this.paymentDetail.detail.planname,
          object_id: "",
          object_name: "",
          object_quality: "",
          platform: this.getPlatform(),
          platform_type: browser.browser,
        };

        //google analytics event
        this.subscriptionFailed(data);

        // //clevertap event
        // this.paymentFailedCleverEvent(data);
      }
    });

    eventBus.$on("payment-confirmation", (response) => {
      // this.loader = true;
      this.showPayment = false;
      this.isPaymentSuccess = true;
      this.paymentConfirmationCallback(response);
    });

    eventBus.$on("paymentconfirmation-response", (response) => {
      if (response.transactionstatus === "SUCCESS") {
        console.log("paypal confirmation response", response);
        if (this.transactionType === "SUBSCRIPTION") {
          this.paymentSuccessResponse = "You have successfully subscribed to plan";
          this.setNewSubscription(true);
        } else if (this.transactionType === "PURCHASE") {
          this.paymentSuccessResponse = "You have successfully purchased the content";
          this.setNewPurchase(true);
        }
        this.transactionSuccess = true;
      }
    });

    // eventBus.$on("paypal-paymentconfirmation", response => {
    //   this.showPayment = false;
    //   this.isPaymentSuccess = true;
    //   this.paymentConfirmationCallback(response);
    // });

    eventBus.$on("free-subscription-response", (response) => {
      console.log("response for free subscription", response);
      if (!response.reason) {
        this.showPayment = false;
        this.isPaymentSuccess = true;
        this.transactionType = "SUBSCRIPTION";
        this.paymentSuccessResponse = "You have successfully subscribed to plan";
        this.transactionSuccess = true;
        this.loader = false;
        this.setNewSubscription(true);

        //free subscription event

        let payload = {
          Subscription_Start_Date: response.planstartdate,
          Subscription_End_Date: response.nextbilling,
          Transaction_ID: "",
          Amount: 0,
          Currency_Code: response.currency,
          Payment_ID: "",
          Promo_Code: response.couponid,
          Discount_Amount: response.discount,
          Plan_ID: response.planid,
          Plan_Name: response.planname,
        };

        //cevertap event
        this.chargedCleverEvent(payload);

        //branch purchase subscribe event
        this.purchaseSubscribeBranchEvent(payload);
      } else {
        this.isPaymentSuccess = false;
        this.paymentError = response.reason;
      }
    });

    eventBus.$on("free-purchase-response", (response) => {
      console.log("response for free purchase", response);
      if (!response.reason) {
        this.showPayment = false;
        this.isPaymentSuccess = true;
        this.paymentSuccessResponse = "You have successfully purchased the content";
        this.transactionSuccess = true;
        this.setNewPurchase(true);

        //free purchase event

        let payload = {
          Amount: 0,
          Currency_Code: response.currency,
          Payment_ID: "",
          Promo_Code: response.couponid,
          Discount_Amount: response.discount,
          Object_ID: response.objectid,
          Object_Name: this.paymentDetail.title,
          Object_Quality: this.paymentDetail.contentdetails[0].quality,
        };

        //cevertap event
        this.chargedCleverEvent(payload);
        //branch purchase subscribe event
        this.purchaseSubscribeBranchEvent(payload);
      } else {
        this.isPaymentSuccess = false;
        this.paymentError = response.reason;
      }
    });
  },
  methods: {
    ...mapMutations(["setNewSubscription", "setNewPurchase"]),
    ...mapActions(["paymentConfirmation"]),

    subscriptionPrecheck(paymentDetail) {
      this.precheckResponse(paymentDetail)
        .then((response) => {
          console.log("precheck response -- Checkout -- Paypal", response, paymentDetail);
          if (response.reason) {
            this.showPayment = false;
            this.paymentError = response.reason;
          } else {
            this.listedGateways = this.paymentDetail.gateways;
            this.showPayment = true;
            this.paypalDetail = this.setUpGatewayDetail("PAYPAL");
            this.checkoutDetail = this.setUpGatewayDetail("CHECKOUT");
            this.paypalSubDetail = this.setUpGatewayDetail("PAYPALSUB");

            this.paymentPrecheckData = response.data;

            if(response.data && response.data.finalamount === 0) {
              let payload = {
                transactionpurpose: paymentDetail.transactionPurpose,
                planid: response.data.planid,
                availabilityid: paymentDetail.detail.availabilityset[0],
              };
              eventBus.$emit("create-free-subscription", payload);
            } 

            const analyticsData = {
              nextbilling: response.data.nextbilling,
            };

            localStorage.setItem("payment_analytics_data", JSON.stringify(analyticsData));
          }
        })
        .catch((error) => console.log(error));
    },

    precheckResponse(detail) {
      console.log("detaill",detail)
      let payload = {
        transactionpurpose: detail.transactionPurpose,
        planid: detail.detail.planid,
      };
      return store.dispatch("subscriptionPrecheck", payload);
      // eventBus.$emit("subscribe-precheck", payload);
    },

    retrievePlanInterval(paymentDetail, displayLanguage) {
		// 	if(paymentDetail.detail.planid === "2NJKC6IT"){
		// 		if (displayLanguage == "ara") {
		// 			return (
		// 				(paymentDetail.detail.planinterval === "QUARTER"
		// 					? "3" + " " + this.$t("quarter")
		// 					: this.$t(paymentDetail.detail.planinterval)) +
		// 				" " +
		// 				"/" +
		// 				" " +
		// 				paymentDetail.detail.currency +
		// 				" " +
		// 			this.successAmount / 100
		// 			);
		// 		} else {
    //     return (
    //       paymentDetail.detail.currency +
    //       " " +
    //       this.successAmount / 100 +
    //       " " +
    //       "/" +
    //       " " +
    //       (paymentDetail.detail.planinterval === "QUARTER"
    //         ? this.$t("quarter")
    //         : this.$t(paymentDetail.detail.planinterval))
    //     );
    //   }
		// }else {
		if (displayLanguage == "ara") {
        return (
          (paymentDetail.detail.planinterval === "QUARTER"
            ? "3" + " " + this.$t("quarter")
            : this.$t(paymentDetail.detail.planinterval)) +
          " " +
          "/" +
          " " +
          paymentDetail.detail.currency +
          " " +
          this.successAmount / 100
        );
      } else {
        return (
          paymentDetail.detail.currency +
          " " +
          this.successAmount / 100 +
          " " +
          "/" +
          " " +
          (paymentDetail.detail.planinterval === "QUARTER"
            ? this.$t("quarter")
            : this.$t(paymentDetail.detail.planinterval))
        );
      }
//}
    },

    // subscriptionPrecheck() {
    //   let payload = {
    //     transactionpurpose: this.paymentDetail.transactionPurpose,
    //     planid: this.paymentDetail.detail.planid
    //   };
    //   eventBus.$emit("subscribe-precheck", payload);

    //   console.log("precheck event emitted");
    // },

    getPaymentDetail() {
      let payload = {
        referenceid: this.paymentDetail.referenceid,
      };
      eventBus.$emit("paymentDetails", payload);
    },

    //checking loader UI.
    paymentCheck() {
      this.showPayment = false;
      this.loader = false;
      this.isPaymentSuccess = true;
      this.transactionSuccess = true;
      // this.decrementCounter();
    },

    //paypal payment confirmation check.
    paymentConfirmationCallback(response) {
      let paymentdata = response.paymentdata;
      let referencedata = response.referencedata;

      referencedata.referencedata.payerID = paymentdata.payerID;
      referencedata.referencedata.orderID = paymentdata.orderID;

      let payload = {
        gatewayid: response.gatewayid,
        params: {
          referencedata: referencedata,
        },
      };

      eventBus.$emit("paymentConfirmation", payload);
    },

    //showing loader for 10 seconds so the payment status will be changed to success for razorpay.
    decrementCounter() {
      if (this.counter > 1) {
        let timer = setInterval(() => {
          this.counter--;
          if (this.counter < 0) {
            clearInterval(timer);
            this.checkPaymentStatus();
          }
        }, 1000);
      }
    },

    //razor pay payment confirmation check.
    checkPaymentStatus() {
      let payload = {
        referenceid: this.paymentReferenceid,
      };
      eventBus.$emit("paymentDetails", payload);
    },

    //get Portrait poster for success card.
    getPoster() {
      let content = this.paymentDetail.content;
      if (content.poster && content.poster.length > 0) {
        let poster = null;
        let index = content.poster.findIndex((element) => {
          return element.postertype === "PORTRAIT" && element.quality === "HD";
        });
        if (index > -1) {
          content.poster[index].filelist.forEach((element) => {
            if (element.quality === "HD") {
              poster = element.filename;
            }
          });
        }
        return poster;
      }
    },

    setUpGatewayDetail(gateway) {
      let index = this.listedGateways.findIndex((element) => {
        return element.gatewayid === gateway;
      });

      if (index > -1) {
        return this.listedGateways[index];
      }
    },
    totalAfterDiscount(payload) {
      this.finalPromoAmounts = payload;
      eventBus.$emit("finalPromoAmounts", payload);
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "popup" */ "@/components/Slots/Popup.vue"),
    OrderSummary: () => import(/* webpackChunkName: "Summary" */ "./OrderSummary.vue"),
    PaymentOptions: () => import(/* webpackChunkName: "PaymentOptions" */ "./PaymentOptions.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    NoorPaymentOptions: () =>
      import(/* webpackChunkName: "NoorPaymentOptions" */ "@/components/Popups/NoorPayments/NoorPaymentOptions.vue"),
  },
  beforeDestroy() {
    // eventBus.$off(`precheck-response-${this.paymentDetail.detail.planid}`);
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("free-subscription-response");
  },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.vlive-payment {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.precheck {
  color: #fff;
  padding: 2.6rem;
}
.paymentError {
  color: #efeff4;
  font-family: $font-regular;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.paymentSuccess {
  padding: 2.6rem;
  color: #efeff4;
  font-family: $font-regular;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  .please-wait {
    color: #b8c0c7;
    font-family: $font-regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
  }
  .payment-progress {
    color: #70787f;
    font-family: $font-regular;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 27px;
  }
  .loading-gif {
    width: 85px;
  }
  .payment-successfull {
    color: #b8c0c7;
    font-family: $font-regular;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    text-align: center;
    margin-bottom: 20px;
  }
  .subscription-success-card {
    border: 1px solid #32363e;
    border-radius: 10px;
    background-color: #191b20;
    position: relative;
    padding: 0.6rem;
    text-align: left;
    .tick {
      position: absolute;
      top: -5px;
      right: -5px;
    }
    .plan-title {
      color: #f8fafd;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }
    .plan-type {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .plan-interval {
      color: #4d7bff;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  .subscription-success-card-right {
    border: 1px solid #32363e;
    border-radius: 10px;
    background-color: #191b20;
    position: relative;
    padding: 0.6rem;
    text-align: right;
    .tick {
      position: absolute;
      top: -5px;
      left: -5px;
    }
    .plan-title {
      color: #f8fafd;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
    }
    .plan-type {
      color: #70787f;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.3px;
      line-height: 27px;
    }
    .plan-interval {
      color: #4d7bff;
      font-family: $font-regular;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 30px;
    }
  }
  .purchase-success-card {
    .poster-card {
      height: 150px;
      width: 100px;
      background-size: contain;
      background-position: center;
      border-radius: 5px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 10px;
      .purchase-tick {
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
    .purchase-content-title {
      color: #cecece;
      font-family: $font-regular;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.39px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .vlive-payment {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
